
import * as Yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { sign } from "jsonwebtoken";
import ApiService from "@/core/services/ApiService";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment-timezone";

export default {
  props: ["prevStep", "goTo", "closeModal", "showChangeCardModal"],
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const currentUser = computed(() => store.getters.currentUser);
    const disableDiscard = ref(false);
    const currentYear = ref(moment().year());
    const currentMonth = ref(moment().month() + 1);
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );

    const dateList = ref(range(currentYear.value, currentYear.value + 11, 1));
    const subscriptionFailed = ref(false);
    const cardSchema = Yup.object().shape({
      cardName: Yup.string()
        .required()
        .label("Card holder name"),
      cardNumber: Yup.string()
        .required()
        .min(15)
        .max(16)
        .label("Card number"),
      cardExpMonth: Yup.string()
        .required()
        .label("Month"),
      cardExpYear: Yup.string()
        .required()
        .label("Year"),
      cardCvv: Yup.string()
        .min(3)
        .max(4)
        .required()
        .label("CVV")
    });

    const planId = "60c0fb3c8ec671273326ece7";
    const createSubscription = async data =>
      ApiService.post("/v1/subscription", data);
    const updateMemberSubscription = async managerData =>
      ApiService.put(
        "/v1/subscription/update-member-subscription",
        managerData
      );
    const setPaymentMethod = async values => {
      /* eslint-disable @typescript-eslint/camelcase */
      if (values.cardExpYear === currentYear.value) {
        if (
          currentMonth.value === 12 ||
          values.cardExpMonth <= currentMonth.value
        ) {
          Swal.fire({
            icon: "error",
            text: "Invalid expiry date.",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        }
      }
      const token = await sign(
        JSON.parse(
          JSON.stringify({
            billing_details: {
              name: values.cardName,
              email: currentUser.value.email,
              phone: currentUser.value.contactNumber
            },
            card: {
              number: values.cardNumber,
              exp_month: values.cardExpMonth,
              exp_year: values.cardExpYear,
              cvc: values.cardCvv
            }
          })
        ),
        process.env.VUE_APP_SECRET
      );

      const submitButton = document.querySelector("#submit_pm") as HTMLElement;

      try {
        submitButton.setAttribute("disabled", "disabled");
        submitButton.innerText = "Submitting...";

        const createPaymentMethod = data =>
          ApiService.put("/v1/subscription/payment-method", data);

        const { data } = await createPaymentMethod({ token });
        if (data.success) {
          submitButton.removeAttribute("disabled");
          submitButton.innerText = "Submit";

          if (
            !currentUser.value.manager &&
            currentUser.value.stripe.subscription.nickname === "15D Trial"
          ) {
            const { data } = await createSubscription({
              planId: planId,
              quantity: currentUser.value.stripe.subscription.quantity,
              resubscribe:
                currentUser.value.stripe.subscription.status === "canceled",
              managerId: currentUser.value.id
            });

            if (data.success) {
              await updateMemberSubscription({
                managerId: currentUser.value.id
              });

              Swal.fire({
                title: `Done`,
                text: `Payment method updated.`,
                icon: "success"
              }).then(() => {
                window.location.reload();
              });
            } else {
              subscriptionFailed.value = true;
              Swal.fire({
                icon: "error",
                text: data.message || "Unable to subscribe.",
                buttonsStyling: false,
                confirmButtonText: "Try again",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger"
                }
              });
            }
          } else {
            Swal.fire({
              title: `Done`,
              text: `Payment method updated.`,
              icon: "success"
            }).then(() => {
              window.location.reload();
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: data.message || "Unable to register.",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        }
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "Unable to register.",
          buttonsStyling: false,
          confirmButtonText: "Try again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }

      submitButton.removeAttribute("disabled");
      submitButton.innerText = "Submit";
    };

    const handleSubscribe = async () => {
      try {
        const { data } = await createSubscription({
          planId: planId,
          quantity: currentUser.value.stripe.subscription.quantity,
          resubscribe:
            currentUser.value.stripe.subscription.status === "canceled",
          managerId: currentUser.value.id
        });

        if (data.success) {
          await updateMemberSubscription({
            managerId: currentUser.value.id
          });
        }
      } catch (error) {
        let message;

        if (error instanceof Error) {
          message = error.message;
        } else {
          const err = error as AxiosError;
          message = err.response?.data.message;
        }

        Swal.fire({
          icon: "error",
          text: message || "Unable to register.",
          buttonsStyling: false,
          confirmButtonText: "Try again",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });

        console.log({ error });
      }
    };
    onMounted(() => {
      const { step } = route.query;
      if (step) {
        disableDiscard.value = true;
      }
    });

    return {
      cardSchema,
      setPaymentMethod,
      disableDiscard,
      currentYear,
      currentMonth,
      dateList,
      subscriptionFailed,
      handleSubscribe
    };
  }
};
